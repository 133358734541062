.vote-title {
    padding-top: 20px;
}

.form {
  display: flex;
  justify-content: center;
}

iframe {
  width: 1000px;
  height: 1750px;
  border: none;
}
